import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"

import PostCard from "./post-card"

const PostMaker = ({ data }) => (
  <section className="home-posts">
    <h2 className="taxonomy-header">最新記事</h2>
    <div className="grids col-1 sm-2 lg-3">
      {data}
    </div>


    <div className="pagination">
      <ul>
        <li>
          <Link to="/archive/1/" className="is-active num" disable aria-label="NOW">1</Link>
        </li>
        <li className="nextpage">
        <Link to="/archive/2/" className="num" aria-label="NEXT">2</Link>
        </li>
        <li>
          <Link to="/archive/2/" rel="next">
            次ページ <span className="icon -right"><RiArrowRightSLine /></span>
          </Link>
        </li>
      </ul>
    </div>

  </section>
)

export default function BlogListHome() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {frontmatter: {template: {eq: "blog-post"}, draft: {ne: true}}}
            limit: 9
          ) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  date(formatString: "YYYY/MM/DD")
                  slug
                  title
                  category
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 540, maxHeight: 360, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      }

      render={ data => {
          const posts = data.allMarkdownRemark.edges
            .filter(edge => !!edge.node.frontmatter.date)
            .map(edge =>
              <PostCard key={edge.node.id} data={edge.node} />
          )
          return <PostMaker data={posts} />
        }
      }
    />
  )
}