import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { RiRefreshLine } from "react-icons/ri"

const _ = require("lodash")
/* eslint-disable import/first */
const PostCard = ({ data }) => (
  <article className="post-card">
    {data.frontmatter.featuredImage ?
      (
        <Link to={data.frontmatter.slug +`/`}>
          <Img
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + ' - Featured image'}
            className="featured-image"
          />
        </Link>
      ) : ""
    }
    <div class="post-content">
      <h2 className="title"><Link to={data.frontmatter.slug +`/`}>{data.frontmatter.title}</Link></h2>
      <div className="archive-meta">
        {data.frontmatter.category
        ? <Link to={`/cat/${_.kebabCase(data.frontmatter.category)}/`}>
        <span className="meta-category">{data.frontmatter.category}</span>
        </Link>
        : ""}
        <time><RiRefreshLine size={14} style={{color:"limegreen",verticalAlign:"-5%",marginRight:"4px"}} />{data.frontmatter.date}</time>
      </div>
    </div>
  </article>
)

export default PostCard