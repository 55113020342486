import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import SEO from "../components/seo"
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';

import kebabCase from "lodash/kebabCase"

export const pageQuery = graphql`
  query HomeQuery($id: String!){
    months:allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {date: {gte: "2020-12-01", lt: "2020-12-31"}}}) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "YYYY/MM")
            year: date(formatString: "YYYY")
            month: date(formatString: "MM")
          }
        }
      }
      totalCount
    }
		markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 480, maxHeight: 380, quality: 80, srcSetBreakpoints: [960, 1440]) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            sizes {
              src
            }
          }
        }
      }
    }
    categories:allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
        ogp
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""
	return (
		<Layout>
      <SEO/>
      <div className="home-banner grids col-1 sm-2">
        <div>
          <h1 class="title">{frontmatter.title}</h1>
          <div className="description" dangerouslySetInnerHTML={{__html: html}}/>
        </div>
        <div>
          {Image ? (
            <Img 
              fluid={Image}
              alt={frontmatter.title + ' - Featured image'}
              className="featured-image"
            />
          ) : ""}
        </div>
      </div>
      <BlogListHome/>

      <div>
        <h2 className="taxonomy-header">カテゴリー</h2>
        <ul className="categories-list">
          {data.categories.group.map(category => (
            <li key={category.fieldValue}>
              <Link to={`/cat/${kebabCase(category.fieldValue)}/`}>
                {category.fieldValue} <span>{category.totalCount}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h2 className="taxonomy-header">アーカイブ</h2>
        <ul className="categories-list">

            <li>
              <Link to="2020/12">
              2020年12月 <span>3</span>
              </Link>
            </li>
            <li>
              <Link to="2020/11">
              2020年11月 <span>8</span>
              </Link>
            </li>

          {/* {data.months.edges.map(month => (
            <li>
              <Link to={`/${month.node.frontmatter.date}`}>
              {month.node.frontmatter.year}年{month.node.frontmatter.month}月 <span>{data.months.totalCount}</span>
              </Link>
            </li>
          ))} */}

        </ul>
      </div>

      <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: 'Home',
              item: `${data.site.siteMetadata.siteUrl}`,
            },
          ]}
        />
		</Layout>
	)
}

export default HomePage
